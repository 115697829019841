
import './bibliotheque.styles.scss';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../3-context/0-user.context';
import { PaymentContext } from '../../3-context/2-payment.context';
import { RequestContext } from '../../3-context/4-request.context';

import { getBibliotheque, getPublicBibliotheque } from '../../4-utils/firebase.utils';

import FormInput from '../../1-components/2-form-input/form-input.component'
import { Link } from 'react-router-dom';

import NotSub from '../../1-components/10-not-sub/not-sub.component';
import Loader from '../../1-components/12-loader/loader.component';

const Bibliotheque = () => {  
  const { currentUser } = useContext(UserContext);
  const { loadingContent, isSub } = useContext(PaymentContext)
  const { setSource } = useContext(RequestContext)
  const [colles, setColles] = useState([]);
  const [filter, setFilter] = useState({ type:'', value: '', matiere: ''});
  const [isFilterEmpty, setIsFilterEmpty] = useState(true)
  const [searchQuery, setSearchQuery] = useState('');
    
  const resetFilter = () => {
    setFilter({ type:'', value: '', matiere: ''});
    setSearchQuery('');
    setIsFilterEmpty(true)
  }
  const fetchColles = async () => {
    if (currentUser) {      
      const collesData = await getBibliotheque(filter, searchQuery);      
      setColles(collesData)
    }
  };

  useEffect(() => {
    fetchColles();
    setIsFilterEmpty(!filter.type && !filter.value && !filter.matiere && !searchQuery)
  }, [currentUser, searchQuery,filter]);

  const renderColles = () => {
    
    if (colles.length === 0) {
      return <div>Aucun résultat disponible..</div>;
    }
    const handleClick = (colle) => {
      sessionStorage.setItem('requestContent', JSON.stringify(colle))
      setSource('bibliotheque')
    }
    return (
      <Fragment>
        {<div className='colles-subtitle'>{filter.matiere ? filter.matiere: "Toutes"}</div>}
        {colles.map((colle, index) => (
          <Link key={index} to={'/pdf'} onClick={() => handleClick(colle)} className='colle' >
            <div className='cadre'>
              <div className='row'>
                <div className={`title ${colle.methode === "plan" && "plan"}`}>
                  {colle.data["user_subject"]}
                </div>
                <div className='matiere'>{colle.matiere}</div>
              </div>
              {
                colle.methode === "plan" ? <div className='content'>Plan détaillé</div>
                : <div className='content'>{colle.data["note"]}/20</div>
              }
            </div>
          </Link>
        ))}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {
        loadingContent  ? <div className='loading'><Loader animation={'loop'}/></div> 
        : (
          <Fragment>
            <div className='bibliotheque-container'>
              <div className='colles-uppercontainer'>
                <div className='colles-title'>La Bibliothèque</div>
                <div className='row-container'> 
                  <div className='search-container'>
                    <FormInput type='text' placeholder='Rechercher une colle...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                  </div>
                  <div className='filter'>
                    <div className='label'>Trier par :</div>
                    <select value={`${filter.type},${filter.value}`} onChange={(e) => {const [type, value] = e.target.value.split(',');setFilter({ ...filter, type: type, value: value})}}>
                      <option value=''>Sélectionner</option>
                      <option value={'methode,plan'} >Plan</option>
                      <option value={'methode,colle'} >Colle</option>
                      <option value={'note,asc'} >Note croissante</option>
                      <option value={'note,desc'}>Note décroissante</option>
                    </select>
                  </div>
                  <div className='filter'>
                    <div className='label'>Matière :</div>
                    <select value={filter.matiere} onChange={(e) => setFilter({ ...filter, matiere: e.target.value })}>
                        <option value=''>Toutes</option>
                        <option value='HGG'>HGG</option>
                        <option value='ESH'>ESH</option>
                        <option value='CG'>CG</option>
                    </select>
                  </div>
                  <div className={`reset-filter-btn ${isFilterEmpty && 'disabled'}`} onClick={resetFilter}>
                    <i className="fa-solid fa-trash"></i>
                  </div>
                </div>
              </div>
              <div className='colles-subcontainer'>
                <Fragment>
                <div className='result-number'>{colles.length}</div>
                {renderColles()}   
                </Fragment>
              </div>
            </div>
          </Fragment>
        )
      }
      </Fragment>
  );
};

export default Bibliotheque;
