import './vente.styles.scss'
import './vente.responsive.scss'
import { Fragment, useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { UserContext } from '../../3-context/0-user.context'
import { PaymentContext } from '../../3-context/2-payment.context'

import { getInfo, setInfo, verificationEmail } from '../../4-utils/firebase.utils'

import Footer from '../../1-components/0-footer/footer.component'
import VenteDescriptif from './vente-descriptif/vente-descriptif.component'
import { adresseCreateCheckoutSession } from '../../0-data/cloudAdress'

const Vente = () => {
    const bottomRef = useRef(null);
    const navigate = useNavigate()

    const { currentUser, curieux } = useContext(UserContext)
    const { setOptions, setOffre, isSub} = useContext(PaymentContext)

    const [checkoutLoading, setCheckoutLoading] = useState(null)
    const [isNotVerifEmail, setIsNotVerifEmail] = useState(false)

    const scrollToBottom = () => {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    const handleSubmit = async (offre_abo) => {
        if (currentUser.emailVerified) 
        {
          setOffre(offre_abo)
          
          setCheckoutLoading(true)

          if (offre_abo === "curieux") navigate('/paymentStatus/return?session_id=curieux')
          else {
            const email = await getInfo(currentUser.uid, 'email')
            const requestBody = JSON.stringify({
              email: email,
              offre: offre_abo
            });
            const res = await fetch(adresseCreateCheckoutSession, {
              method: "POST",
              body: requestBody,
              headers: {
                'Content-Type': 'application/json'
              }
            })
            const responseData = await res.json()
            const clientSecret = responseData.clientSecret
            const customerId = responseData.customerId
            await setInfo(currentUser.uid, "customerId", customerId)
            setOptions({clientSecret})

            setCheckoutLoading(false)
            if (responseData.error) alert("Vous êtes déjà abonné. Si c'est une erreur merci de nous contacter.")
            else navigate('/paymentCheckout')
          }
        }
        else setIsNotVerifEmail(true)
      }
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Pour un défilement fluide
      });
    };

    const handleVerifEmail = async () => {
      try {
        await verificationEmail(currentUser)
        alert("L'email de vérification a été envoyé avec succès")
        setIsNotVerifEmail(false)
      }
      catch(err){
        console.log(err, err.message);
        alert("Une erreur est survenue, merci de réessayer")
      }
    }
    return (
        <div className='vente'>
        {isSub ? (
          <div className='already-sub'>Vous êtes déjà abonné</div>
        ): (
          <Fragment>
            <h1 className='title' id='top'>Prêts à progresser ? &#x1F929;</h1>
            {
              isNotVerifEmail && (
                <div className='blur-container'>
                  <div className='email-verification'>
                    <i className='fa-solid fa-xmark' onClick={() => setIsNotVerifEmail(false)}></i>
                    <h1>Veuillez vérifier votre adresse e-mail</h1>
                    <p>*Si vous venez de le faire, merci d'actualiser la page</p>
                    <button onClick={handleVerifEmail}>Renvoyer un e-mail de vérification</button>
                  </div>
                </div>

              )
            }
            <div className='card-container'>
              {!curieux && (
                <div className='sub-card'>
                    <div className='card-title'>Pour les curieux &#x1F648;</div>
                    <div className='card-description'>Un essai pour se convaincre !</div>
                    <div className='card-price'><span>Gratuit</span></div>
                    <div className='features-container'>
                        <div className='features'>
                            &#x2705;
                            <p>1 colle ou 1 plan détaillé</p>
                        </div>
                        <div className='features'>
                            &#x2705;
                            <p>HGG, ESH et Culture G. </p>
                        </div>
                        <div className='features'>
                            &#x274C;
                            <p>Accède aux colles déjà corrigées</p>
                        </div>
                        <div className='features'>
                            &#x274C;
                            <p>Des milliers de sujets d'annales pour t'entraîner</p>
                        </div>
                    </div>
                    {checkoutLoading ? (
                        <button className='card-buy'>Chargement...</button>
                    ) : (
                        <button className='card-buy' onClick={() => handleSubmit("curieux")}>J'essaie !</button>
                    )}
              </div>
              )}
              <div className='sub-card'>
                  <div className='card-title'>Pour réussir ses oraux &#x1F4AB;</div>
                  <div className='card-description'>Une préparation en béton !</div>
                  <div className='card-price'><span>9.99€</span>/mois</div>
                  <div className='features-container'>
                      <div className='features'>
                          &#x2705;
                          <p>Colles illimitées</p>
                      </div>
                      <div className='features'>
                          &#x2705;
                          <p>Plans détaillés illimités</p>
                      </div>
                      <div className='features'>
                          &#x2705;
                          <p>HGG, ESH et Culture G. </p>
                      </div>
                      <div className='features'>
                          &#x2705;
                          <p>Des centaines de colles déjà corrigées</p>
                          
                      </div>
                      <div className='features'>
                          &#x2705;
                          <p>Des milliers de sujets d'annales pour t'entraîner</p>
                      </div>
                  </div>
                  {checkoutLoading ? (
                      <button className='card-buy'>Chargement...</button>
                  ) : (
                      <button className='card-buy' onClick={() => handleSubmit("complet")}>Je m'abonne !</button>
                  )}
              </div>
            </div>
            <VenteDescriptif/>

            <Footer/>
          </Fragment>
        )}
      </div>
    )
}
export default Vente