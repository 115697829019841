import './analyse.styles.scss'

import { Fragment, useContext, useEffect, useState} from 'react'

import { PaymentContext } from '../../3-context/2-payment.context'
import { AnalyseContext } from '../../3-context/3-analyse.context'
import { AudioContext } from '../../3-context/6-audio.context'

import Prompt from '../../1-components/4-prompt/prompt.component'
import { RequestContext } from '../../3-context/4-request.context'
import Sujet from '../../1-components/5-sujet/sujet.component'
import MiniJeux from '../../1-components/3-mini-jeux/mini-jeux.component'
import { adresseCg, adresseEsh, adresseHgg } from '../../0-data/cloudAdress'

const Analyse = () => {

    const [isMobile, setIsMobile] = useState(false)
    const handleResize = () => {
        setIsMobile(window.innerWidth > 768 ? false : true)
    }
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, []) 
    const {subject, setSubject, fetchUrl, setFetchUrl, setMatiere, matiere, setMethode, methode} = useContext(AnalyseContext)
    const { requestState, setRequestState } = useContext(RequestContext)
    const { setAudioFile, setAudioDuration } = useContext(AudioContext)
    const { loadingContent } = useContext(PaymentContext)
    console.log(loadingContent);
    
    const handleClick = (matiere, url) => {
        setFetchUrl(url) 
        setMatiere(matiere)
    }

    const handleReset = () => {
        setSubject(null)
        setFetchUrl(null)
        setMatiere(null)
        setMethode(null)
        sessionStorage.setItem('requestContent', null)
        setRequestState(null)
        setAudioFile(null)
        setAudioDuration(null)
    }

    useEffect(() => {
        handleReset()
    }, [])
   return (
        <div className='analyse'>
            {
            isMobile ? (
                <div className='responsive-alert'>Connectez-vous à MonColleur depuis un ordinateur pour profiter d'une expérience optimale !</div>
            ) :
            loadingContent ? (
                <div className='loading-content'>Le contenu est en cours de chargement..</div>
            ): (
                <div className='analyse-container'>
                {
                    requestState ? (
                        <MiniJeux/>
                    ) 
                    : subject === false ? (
                        <Fragment>
                            <div className='back' onClick={() => setSubject(null)}>
                                <i className="fa-solid fa-arrow-left"></i>
                            </div>
                            <Sujet matiere={matiere}/>
                        </Fragment>
                    ) : subject || subject === '' ? (  
                        <Fragment>
                            <div className='back' onClick={() => setSubject(null)}>
                                <i className="fa-solid fa-arrow-left"></i>
                            </div>
                            <Prompt subject={subject} methode={methode}/>
                        </Fragment>
                    ) : fetchUrl ? (
                        <Fragment>
                            <div className='back' onClick={() => handleClick(null, null)}>
                                <i className="fa-solid fa-arrow-left"></i>
                            </div>
                            <div className='choice-btn-container-subject'>
                                <button onClick={() => setSubject('')} className='choice-btn'>J'ai déjà un sujet</button>
                                <button onClick={() => setSubject(false)} className='choice-btn'>Tirer un sujet aléatoire</button>
                            </div>
                        </Fragment>
                    ): methode ? (
                        <Fragment>
                            <h1 className='analyse-title'>Choisis ta matière</h1>
                            <div className='back' onClick={() => setMethode(null)}>
                                <i className="fa-solid fa-arrow-left"></i>
                            </div>
                            <div className='choice-btn-container'>
                                <button className='choice-btn' onClick={() => handleClick('HGG', adresseHgg)}>HGG</button>
                                <button className='choice-btn' onClick={() => handleClick('ESH', adresseEsh)}>ESH</button>
                                <button className='choice-btn' onClick={() => handleClick('CG', adresseCg)}>CG</button>
                            </div>
                        </Fragment>
                    ) :  (
                        <Fragment>
                            <div className='methode-container'>
                                <div className='methode-btn' onClick={() => setMethode('colle')}>Passer une colle</div>
                                <div className='methode-btn' onClick={() => setMethode('plan')}>Générer un plan détaillé</div>
                            </div>
                        </Fragment>
                    )
                }
                </div>
            )}
        </div>
    )
}

export default Analyse